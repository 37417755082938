import React from 'react'
import WeCreatePageSeoContainer from '@containers/WeCreatePageSeoContainer'
import WeCreateMainPageContainer from '@containers/WeCreateMainPageContainer'

const IndexPage = () => {
  return (
    <>
      {/* SEO */}
      <WeCreatePageSeoContainer />
      {/* content container */}
      <WeCreateMainPageContainer />
    </>
  )
}

export default IndexPage
